import HTTPStatusCode from "../utils/HTTPStatusCode";
import { displayErrorToast } from "../utils/ToastUtils";
import { handleUnauthorisedException } from "../utils/apiUtils";
import { baseURL } from "../utils/constants";
import axios from "axios";

export const fetchTopicsFromText = async (
  accessToken: string,
  questionText: string
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/ai/topics`,
      {
        question_text: questionText,
        exam: "NEET",
        subject: "Physics",
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get batches");
    return null;
  }
};

export const fetchQuestionFromImage = async (
  accessToken: string,
  base64: string | ArrayBuffer | null
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/ai/question-images`,
      {
        base64_img: base64,
        exam: "NEET",
        subject: "Physics",
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get batches");
    return null;
  }
};

export const fetchQuestionRecommendation = async (
  accessToken: string,
  topics: any
) => {
  try {
    const response = await axios.post<any>(
      `${baseURL}/api/v1/ai/recommend-questions?question_limit=10`,
      topics,
      {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    // type any might be unsafe. We should add some form of type checking.
    if (error?.response?.status === HTTPStatusCode.UNAUTHORIZED) {
      console.error("Unauthorised request");
      handleUnauthorisedException();
      return null;
    }
    displayErrorToast("Failed to get batches");
    return null;
  }
};
