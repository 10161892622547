import { useState } from "react";

export default function CsvReader() {
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);

  const processCSV = (str) => {
    const headers = str.slice(0, str.indexOf("\n")).split(",");
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
      if (values !== null) {
        const eachObject = headers.reduce((obj, header, i) => {
          /**
           * Has three levels of replaces
           * First one is used to replace trailing " if any
           * Second one repalces "" introduced due to parsing
           * The remaining are a hack for videos to have a standard width and height
           */
          obj[header] = values[i].replace(/^\"|\"$/g, '').replace(/\"\"/g, '"').replace('width="100%"', 'width="500px" height="300px"').replace("width:100%", "width:500px").replace("height:100%", "height:300px");
          console.log(obj[header])
          return obj;
        }, {});
        return eachObject;
      }
      return {
        Question: "",
        Explanation: "",
      };
    });
    setCsvArray(newArray);
  };

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };
    reader.readAsText(file);
  };

  return (
    <div
      style={{
        margin: "30px",
      }}
    >
      <form id="csv-form">
        <input
          type="file"
          accept=".csv"
          id="csvFile"
          onChange={(e) => {
            setCsvFile(e.target.files[0]);
          }}
        ></input>
        <br />
        <button
          onClick={(e) => {
            e.preventDefault();
            if (csvFile) submit();
          }}
        >
          Submit
        </button>
        <br />
        <br />
        {csvArray.length > 0 ? (
          <>
            {csvArray.map((row, i) => {
              return (
                <>
                  <h3>Question {i + 1}</h3>
                  <p dangerouslySetInnerHTML={{ __html: row.Question }} />
                  <br />
                  <h3>Solution</h3>
                  <p dangerouslySetInnerHTML={{ __html: row.Explanation }} />
                  <hr />
                </>
              );
            })}
          </>
        ) : null}
      </form>
    </div>
  );
}
