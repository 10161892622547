import {
  fetchQuestionRecommendation,
  fetchQuestionFromImage,
  fetchTopicsFromText,
} from "../api/chatgpt";
import CustomButton from "../components/CustomComponent/CustomButton";
import { TAppContext, AppContext } from "../context/AppContext";
import { IQuestion } from "../models/question";
import TipTap from "../utils/TipTap/TipTap";
import { displayErrorToast, displaySuccessToast } from "../utils/ToastUtils";
import AddImageModal from "./question/Components/AddImageModal/AddImageModal";
import Chip from "@mui/material/Chip";
import { useContext, useRef, useState } from "react";
import { RiImageAddFill } from "react-icons/ri";
import Latex from "react-latex";

export default function ChatGPT() {
  const { accessToken } = useContext<TAppContext>(AppContext);
  const [inputQuestion, setInputQuestion] = useState("");
  const [show, setShow] = useState(false);
  const [base64, setBase64] = useState<string>("");
  const [topics, setTopics] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [inputImage, setInputImage] = useState<string>();
  const handleQuestionEdit = (content) => {
    setInputQuestion(content);
  };
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let res = (reader.result as string) ?? "";
      setInputImage(res);
      setBase64(res.replace(/^data:image\/[a-z]+;base64,/, ""));
      setShow(false);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleAddImage = async (idx, file: any) => {
    getBase64(file);
  };
  const handleSubmit = async () => {
    setLoading(true);
    let tps: Array<any> = [];
    if (inputQuestion !== "") {
      tps = await fetchTopicsFromText(accessToken, inputQuestion);
    } else if (base64 !== "") {
      const question_text: string = await fetchQuestionFromImage(
        accessToken,
        base64
      );
      setInputQuestion(question_text);
      tps = await fetchTopicsFromText(accessToken, question_text);
    } else {
      displayErrorToast("Please enter a question");
      setLoading(false);
      return null;
    }
    let temp: Array<any> = [];
    if (!tps) {
      displayErrorToast("No topics found");
      setLoading(false);
      return null;
    }
    tps.forEach((topic: any) => {
      temp.push(topic.tag);
      return null;
    });
    setTopics(temp);
    let questions = await fetchQuestionRecommendation(accessToken, tps);
    setQuestions(questions);
    setLoading(false);
    displaySuccessToast("Questions fetched successfully");
  };
  const cursorPosition = useRef<number>(0);
  return (
    <div className="m-4">
      <AddImageModal
        idx={-1}
        show={show}
        setShow={setShow}
        handleAddImage={handleAddImage}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3>Question</h3>
        <div
          className="border border-dark rounded-4"
          style={{
            marginLeft: "1em",
            borderWidth: 10,
            borderColor: "black",
            display: "flex",
            flexGrow: 1,
            flexDirection: "row",
            minHeight: "10vh",
            alignItems: "center",
            padding: "1em",
            width: "85vw",
          }}
        >
          <TipTap
            editMode
            setCursorPosition={(pos: number) => {
              cursorPosition.current = pos;
            }}
            onChange={(_, content) => handleQuestionEdit(content)}
            text={""}
            idx={-1}
          />
          {inputImage && <img src={inputImage} />}
          <RiImageAddFill
            size="40px"
            color="#9AA0B2"
            onClick={() => {
              setShow(true);
            }}
            cursor="pointer"
          />
        </div>
      </div>
      <div className="m-4">
        <h2>
          The question that was taken as an input/inferred from image was:{" "}
        </h2>
        <TipTap text={inputQuestion} />
      </div>
      <CustomButton className="m-4" onClick={handleSubmit} disabled={loading}>
        Submit
      </CustomButton>
      <div>
        {topics.length !== 0 && <h1>Topics</h1>}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {topics.map((topic) => (
            <Chip
              label={topic}
              variant="filled"
              color="primary"
              style={{
                margin: "0.5em",
                padding: "0.5em",
                fontSize: "1em",
              }}
            />
          ))}
        </div>
      </div>
      <div>
        {questions.length !== 0 && <h1>Questions</h1>}
        <div>
          {questions.map((question, idx) => (
            <div className="m-4">
              <h3>Question {idx + 1}</h3>
              <Latex>{question.body.text}</Latex>
              <h5 className="mt-2">Options:</h5>
              {question.options.map((el, idx) => (
                <p>
                  {idx + 1}. <Latex>{el.text}</Latex>
                </p>
              ))}
              <h5 className="mt-2">
                Answer: {question.answer.split(",").join(" ")}
              </h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
