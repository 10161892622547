import { useEffect, useContext, useState } from "react";
import { Switch, Route } from "react-router-dom";

import { AppContext, TAppContext } from "../context/AppContext";
import { HomeContextProvider } from "../context/HomeContext";
import { AnalyticsContextProvider } from "../context/AnalyticsContext";

import MyLibraryView from "./my-library/MyLibraryView";

import { fetchOrganization } from "../api/organizations";
import MyTests from "./my-tests/MyTests";
import TeacherBatchManagementView from "./batch-management/TeacherBatchManagementView";
import { BatchManagementContextProvider } from "../context/BatchManagementContext";
import QuestionScreen from "./question/QuestionView";
import AttemptInstructionScreen from "./instructionScreen/AttemptInstructionScreen/AttemptInstructionScreen";
import AuthScreen from "./login/Components/AuthScreen";
import { fetchUser } from "../api/user";
import Loading from "../components/Loading/Loading";
import StudentAnalytics from "./analytics/StudentAnalytics";
import StudentAnalyticsForTeacher from "./analytics/StudentsAnalyticsForTeacher";
import StudentResponseScreen from "./question/StudentResponseScreen";
import StudentNTAResponseScreenTeacher from "./question/StudentNTAResponseScreenTeacher";
import StudentOMRResponseScreen from "./question/StudentOMRResponseScreen";
import ResetPassword from "./login/ResetPassword";
import UserFeedbackModal from "./Feedback/Feedback";
import StudentOMRResponseScreenTeacher from "./question/StudentOMRResponseScreenTeacher";
import AnalyticsQuestionPreviewScreen from "./analytics/Screens/AnalyticsQuestionPreviewScreen";
import ProductPage from "./marketplace/ProductPage/ProductPage";
import MarketPlace from "./marketplace/Marketplace";
import ChatGPT from "./chatgpt";
import CsvReader from "./CSVView";
import SalesAnalytics from "./sales-analytics/SalesAnalytics";

export default function HomeView() {
  const {
    accessToken,
    setOrg,
    isLoggedIn,
    setIsOrgOwner,
    setIsBeta,
    setEmail,
    setIsEmailVerified,
    setName,
    setId,
    isEmailVerified,
    isPhoneNumberValid,
    setIsPhoneNumberValid,
  } = useContext<TAppContext>(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [proofRead, setProofRead] = useState<boolean>(false);

  useEffect(() => {
    console.log("Checking if user is logged in");
    if (!isLoggedIn) {
      console.log(
        "User is not logged in, and is restricted from accessing this route"
      );
      return;
    }
    (async () => {
      console.log("Logged in user found, fetching user and org details");
      setIsLoading(true);
      const userInfo = await fetchUser(accessToken);
      if (!userInfo) return;
      setEmail(userInfo.email);
      setIsEmailVerified(userInfo.is_verified);
      setName(userInfo.name);
      setId(userInfo.id);

      // return early if user is not a verified user since only verified users will have an organisation
      if (!isEmailVerified) return setIsLoading(false);

      const orgInfo = await fetchOrganization(accessToken);
      if (!orgInfo?.length) return;

      // Find the org in which the user is the admin
      const owned_orgs = orgInfo.filter((org) => {
        return org.owner_id === userInfo.id;
      });
      setOrg({
        id: owned_orgs[0].id,
        name: owned_orgs[0].name,
        code: owned_orgs[0].org_code,
      });
      setIsOrgOwner(owned_orgs[0].owner_id === userInfo.id);
      setIsBeta(owned_orgs[0].is_beta);
      setIsLoading(false);

      // Just checking if phone number exists or not, if not, user needs to fill out additional info
      if (userInfo.phone !== null) {
        setIsPhoneNumberValid(true);
      } else setIsPhoneNumberValid(false);
    })();
  }, [accessToken, isLoggedIn, isEmailVerified]);

  if (isLoading) return <Loading />;

  if (!isLoggedIn || !isEmailVerified || !isPhoneNumberValid)
    return <AuthScreen />;

  return (
    <div className={`d-flex flex-row w-100`}>
      <div
        className="d-flex bg-white"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          width: "100%",
        }}
      >
        <Switch>
          <HomeContextProvider>
            <Route path="/home/assessments">
              <MyLibraryView />
            </Route>
            <BatchManagementContextProvider>
              <Route path="/home/batch-management/:code">
                <TeacherBatchManagementView />
              </Route>
              <Route exact path="/home/batch/:code/analytics">
                <SalesAnalytics />
              </Route>
            </BatchManagementContextProvider>
            <Route path="/home/dashboard">
              <MyTests />
            </Route>

            <Route exact path="/home/reset-password">
              <ResetPassword isLoggedIn={true} />
            </Route>

            <Route exact path="/home/topic-tagging">
              <ChatGPT />
            </Route>

            <Route exact path="/home/solution-view">
              <CsvReader />
            </Route>

            <Route exact path="/home/attempt/:publishCode/instructions">
              <AttemptInstructionScreen />
            </Route>

            <Route exact path="/home/attempt/:publishCode/question">
              <QuestionScreen />
            </Route>

            <Route exact path="/home/attempt/:publishCode/response">
              {/* <ProofRead proofRead={proofRead} setProofRead={setProofRead}> */}
              <StudentResponseScreen
                proofRead={proofRead}
                setProofRead={setProofRead}
              />
              {/* </ProofRead> */}
            </Route>

            <Route
              exact
              path="/home/response/:publishCode/student/NTA/:student_id"
            >
              <StudentNTAResponseScreenTeacher
                proofRead={proofRead}
                setProofRead={setProofRead}
              />
            </Route>

            <Route
              exact
              path="/home/response/:publishCode/student/OMR/:student_id"
            >
              <StudentOMRResponseScreenTeacher />
            </Route>

            <Route exact path="/home/attempt/:publishCode/feedback">
              <UserFeedbackModal />
            </Route>

            <Route exact path="/home/attempt/:publishCode/OMR/response">
              <StudentOMRResponseScreen />
            </Route>

            <AnalyticsContextProvider>
              <Route exact path="/home/attempt/:publishCode/analytics">
                <StudentAnalytics />
              </Route>
              <Route
                exact
                path="/home/attempt/:publishCode/analytics/student/:studentId"
              >
                <StudentAnalyticsForTeacher />
              </Route>
              <Route
                exact
                path="/home/attempt/:publishCode/analytics/question/:id/preview"
              >
                <AnalyticsQuestionPreviewScreen />
              </Route>
            </AnalyticsContextProvider>
            {/* This is the default route for home which currently redirects to MyTests which is the dashboard */}
            {/* We've done duplication here to avoid multi-route matching at once. */}
            <Route exact path="/home">
              <MyTests />
            </Route>
            <Route exact path="/home/marketplace/">
              <MarketPlace />
            </Route>
            <Route exact path="/home/marketplace/:code">
              <ProductPage />
            </Route>
          </HomeContextProvider>
        </Switch>
      </div>
    </div>
  );
}
