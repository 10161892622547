import React, {
  Dispatch,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AppContext, TAppContext } from "../../../../context/AppContext";
import Splitter from "@devbookhq/splitter";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  defaultLayoutPlugin,
  ToolbarProps,
} from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { RiDeleteBin2Fill } from "react-icons/ri";
import Dropzone from "react-dropzone";
import styles from "./ProofRead.module.scss";
import ClassNames from "classnames/bind";
import { uploadFileToS3 } from "../../../../utils/s3Utils";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../utils/ToastUtils";
import { patchAssessment } from "../../../../api/assessment";
import { TAssessmentData } from "../../../../models/assessmentmodel";
import { Button } from "react-bootstrap";
import { ERole } from "../../../../utils/Enums";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { ImSad2 } from "react-icons/im";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import pdfjsWorker from "pdfjs-dist/es5/build/pdf.worker.entry";

let cx = ClassNames.bind(styles);

interface ProofReadProps {
  proofRead: boolean;
  setProofRead: Dispatch<SetStateAction<boolean>>;
  edit?: boolean;
  setEdit?: Dispatch<SetStateAction<boolean>>;
  isViewingResponses?: boolean;
}

const ProofRead: FunctionComponent<ProofReadProps> = (props) => {
  const { accessToken, role } = useContext<TAppContext>(AppContext);
  const { selectedAssessment, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);
  const [initialSizes, setInitialSizes] = useState<number[]>();
  const [showPDFDeleteModal, setShowPDFDeleteModal] = useState<boolean>(false);

  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <>
      <Toolbar />
      {role === ERole.TEACHER && (
        <Tooltip
          enterTouchDelay={0}
          title={`Delete PDF`}
          arrow={true}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "common.black",
                "& .MuiTooltip-arrow": {
                  color: "common.black",
                },
                fontSize: "17px",
              },
            },
          }}
        >
          <IconButton
            onClick={() => {
              setShowPDFDeleteModal(!showPDFDeleteModal);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: () => [],
  });

  const fileUrl =
    role === ERole.TEACHER
      ? selectedAssessment.teachers_pdf_url
      : props.isViewingResponses
      ? selectedAssessment.solution_pdf_url
      : selectedAssessment.no_solution_pdf_url;

  let content: ReactElement;

  if (role === ERole.TEACHER && !fileUrl) {
    content = <UploadPDF />;
  } else if (role === ERole.STUDENT && !fileUrl) {
    content = <NoPDFUploaded />;
  } else {
    content = (
      <Worker workerUrl={pdfjsWorker}>
        <Viewer
          defaultScale={0.9}
          fileUrl={fileUrl}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
    );
  }

  const isDesktopOrLaptop: boolean = useMediaQuery("(min-width: 992px)");

  useEffect(() => {
    if (props.proofRead) {
      if (isDesktopOrLaptop) setInitialSizes(undefined);
      else setInitialSizes([0, 100]);
    } else setInitialSizes([100, 0]);
  }, [props.proofRead, isDesktopOrLaptop]);
  //this useEffect is called whenever the proof read variable from the parent variable changes and those changes
  // are reflected in this child element, adding this variable to useEffect dependency array allows the state chage to passed
  // from the parent component to the child component

  let OMRGutterClass = cx({
    mobileViewGutter: !isDesktopOrLaptop,
    DesktopViewGutter: !props.proofRead,
  });

  //this className is responsible for managing the css for the content divider when in mobile view like minimising the width to 1 px and chaning the color so that it is hidden with the background
  const UpdateQuestionPDFUrl = async (updateWithSolution: string) => {
    const updatedAssessmentResponse = await patchAssessment(
      accessToken,
      selectedAssessment.id,
      undefined,
      undefined,
      updateWithSolution,
      undefined
    );
    if (updatedAssessmentResponse === null) return;
    setSelectedAssessment({ ...selectedAssessment, teachers_pdf_url: "" });
    displaySuccessToast("PDF Removed Successfully");
    setShowPDFDeleteModal(false);
  };

  return (
    <div style={{ color: "black", height: "92%" }}>
      <ConfirmationModal
        showModal={showPDFDeleteModal}
        setShowModal={setShowPDFDeleteModal}
        onConfirm={() => {
          UpdateQuestionPDFUrl("");
        }}
        confirmText={`Delete`}
        modalTitle={`Delete PDF`}
        modalText={`Are you sure you want to delete PDF`}
      />
      <Splitter
        gutterClassName={OMRGutterClass}
        draggerClassName={OMRGutterClass}
        initialSizes={initialSizes}
        onResizeFinished={(_, newSizes) => {
          setInitialSizes(newSizes);
        }}
      >
        <div className="h-100">
          {!props.proofRead || isDesktopOrLaptop ? props.children : <div></div>}
        </div>
        {content}
      </Splitter>
    </div>
  );
};

export default ProofRead;

const UploadPDF: FunctionComponent = () => {
  const widthRef = React.useRef<HTMLDivElement | null>(null);
  const { accessToken, isLoggedIn, setShowLoginModal } =
    useContext<TAppContext>(AppContext);
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const { selectedAssessment, setSelectedAssessment } =
    useContext<TAssessmentContext>(AssessmentContext);

  const onDrop = (files: any) => {
    setSelectedFiles({ ...files });
  };

  const deletePDF = async () => {
    setSelectedFiles(undefined);
  };

  const uploadPDF = async () => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    if (!selectedFiles)
      return displayErrorToast("Please select a question paper to upload");
    if (selectedFiles !== undefined) {
      // Upload file to the s3 Bucket
      const fileKey = await uploadFileToS3(
        selectedFiles[0],
        `raw-assessment-files/assessment_pdf/${selectedAssessment.id}/`,
        false
      );

      if (!fileKey) {
        return displayErrorToast("Error in uploading assessment pdf");
      }

      // the s3 URL where the file was uploaded
      const fileUrl = `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_S3_REGION}.amazonaws.com/${fileKey}`;

      // patch the assessment with the s3 url in the `no_solution_pdf_url field`
      const response: TAssessmentData = await patchAssessment(
        accessToken,
        selectedAssessment.id,
        undefined,
        undefined,
        undefined,
        fileUrl
      );

      // update the selected assessment present in the local storage
      setSelectedAssessment({ ...response });

      displaySuccessToast("Assessment pdf uploaded successfully!");
    }
  };
  const [innerWidthForPDFRenderer, setInnerWidthForPDFRenderer] =
    React.useState<null | undefined | number>(0);
  useEffect(() => {
    setInnerWidthForPDFRenderer(widthRef.current?.offsetWidth);
  }, [widthRef.current?.offsetWidth]);

  if (innerWidthForPDFRenderer && innerWidthForPDFRenderer >= 179)
    return (
      <div
        ref={widthRef}
        className="p-5 h-100 d-flex flex-column justify-content-center align-items-center"
      >
        No PDF was found. Please upload a PDF for your assessment. This will
        only be visible to you.
        <div className="mt-2">
          {selectedFiles && selectedFiles[0] && (
            <div className="justify-content-end d-flex">
              <RiDeleteBin2Fill
                color="red"
                style={{
                  cursor: "pointer",
                  marginBottom: "-20px",
                }}
                onClick={deletePDF}
              />
            </div>
          )}
          <Dropzone accept=".pdf" onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: styles.dropzone })}>
                  <input {...getInputProps()} />
                  {selectedFiles && selectedFiles[0]?.name ? (
                    <div className={styles.selectedFile}>
                      {selectedFiles[0]?.name}
                    </div>
                  ) : (
                    "Drag and drop file here, or click to select file"
                  )}
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        <Button
          className="border border-none px-5 bg-secondary rounded-4"
          onClick={uploadPDF}
        >
          Upload
        </Button>
      </div>
    );
  else {
    return (
      <div
        ref={widthRef}
        className="p-5 h-100 d-flex flex-column justify-content-center align-items-center"
      ></div>
    );
  }
};

const NoPDFUploaded: FunctionComponent = () => {
  return (
    <div className="p-5">
      <div className="d-flex flex-column bg-card rounded-4 p-5 mt-0 text-center">
        <div className="d-flex flex-row justify-content-center">
          <ImSad2 size={100} />
        </div>
        <div
          className="pt-5 fs-3 justify-content-center"
          style={{ color: "#9AA0B2" }}
        >
          There was no PDF uploaded for this assessment
        </div>
      </div>
    </div>
  );
};
