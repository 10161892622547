import { FunctionComponent, useContext, useEffect, useState } from "react";
import { AppContext, TAppContext } from "../../../context/AppContext";
import AdditionalInfo from "../AdditionalInfo";
import Login from "../Login";
import Signup from "../Signup";
import VerifyEmailPlaceholder from "../VerifyEmailPlaceholder";

export enum EAuthMode {
  LOGIN = 0,
  SIGNUP = 1,
  VERIFY_EMAIL = 2,
  ADDITIONAL_INFO = 3,
}

const AuthScreen: FunctionComponent = () => {
  const { isLoggedIn, isEmailVerified, role, isPhoneNumberValid } =
    useContext<TAppContext>(AppContext);
  const [authMode, setAuthMode] = useState<EAuthMode>(EAuthMode.LOGIN);
  let authModalContent;

  useEffect(() => {
    if (!isLoggedIn) return setAuthMode(EAuthMode.LOGIN);
    if (!isEmailVerified) return setAuthMode(EAuthMode.VERIFY_EMAIL);
    if (!isPhoneNumberValid) return setAuthMode(EAuthMode.ADDITIONAL_INFO);
  }, [isLoggedIn, isEmailVerified, role]);

  switch (authMode) {
    case EAuthMode.LOGIN: {
      authModalContent = <Login setAuthMode={setAuthMode} />;
      break;
    }
    case EAuthMode.SIGNUP: {
      authModalContent = <Signup setAuthMode={setAuthMode} />;
      break;
    }
    case EAuthMode.VERIFY_EMAIL: {
      authModalContent = <VerifyEmailPlaceholder setAuthMode={setAuthMode} />;
      break;
    }
    case EAuthMode.ADDITIONAL_INFO: {
      authModalContent = <AdditionalInfo setAuthMode={setAuthMode} />;
      break;
    }
    default: {
      authModalContent = <Login />;
      break;
    }
  }

  return authModalContent;
};

export default AuthScreen;
